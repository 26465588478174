
export default defineNuxtRouteMiddleware((to, from) => {
  const locale = window.localStorage.getItem('locale')
  const token = window.localStorage.getItem('token');
  if (!token || !locale) {
    window.localStorage.clear();
    if (to.fullPath !== '/') {
      return navigateTo('/');
    }
  }
  return true;
});

